import { AxiosError } from 'axios'
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

import api from '@/utils/api'

import { ITeamspace, ITeamspaceChatsResponse, ITeamspaceResponse } from '../models/ITeamspace'

export const PATHS = {
  teamspaces: 'api/teamspaces',
  teamspaceChats: 'api/teamspaces/:teamspaceId/chats/',
}

export const enum KEYS {
  teamspaces = 'teamspaces',
  teamspaceChats = 'teamspaceChats',
}

export const useGetTeamspace = (teamspaceId?: string) => {
  return useSuspenseQuery<ITeamspaceResponse, AxiosError>({
    queryKey: [KEYS.teamspaces, teamspaceId],
    queryFn: async () => {
      const response = await api.get<ITeamspaceResponse>(PATHS.teamspaces.replace(':teamspaceId', teamspaceId || ''))

      return response.data
    },
  })
}

export const useGetTeamspaces = () => {
  return useSuspenseQuery<ITeamspaceResponse[], AxiosError>({
    queryKey: [KEYS.teamspaces],
    queryFn: async () => {
      const response = await api.get<ITeamspaceResponse[]>(PATHS.teamspaces)

      return response.data
    },
  })
}

export const useCreateTeamspace = () => {
  const queryClient = useQueryClient()
  return useMutation<ITeamspaceResponse, AxiosError, ITeamspace>({
    mutationFn: async (body) => {
      const response = await api.post<ITeamspaceResponse>(PATHS.teamspaces, body)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.teamspaces] })
    },
  })
}

export const useGetTeamspaceChats = (teamspaceId?: string, options?: { refetchOnWindowFocus: boolean }) => {
  const refetchOnWindowFocus = !!options?.refetchOnWindowFocus || false
  return useSuspenseQuery<ITeamspaceChatsResponse[], AxiosError>({
    queryKey: [KEYS.teamspaceChats],
    refetchOnWindowFocus,
    refetchInterval: refetchOnWindowFocus ? 2500 : false,
    ...(refetchOnWindowFocus ? { staleTime: 0 } : {}),
    queryFn: async () => {
      try {
        const response = await api.get<ITeamspaceChatsResponse[]>(
          PATHS.teamspaceChats.replace(':teamspaceId', teamspaceId || ''),
        )

        return response.data
      } catch (error) {
        Sentry.captureException(error)
        return []
      }
    },
  })
}
