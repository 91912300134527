import React from 'react'
import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import Ticket from '../Ticket'
import { SkeletonComponent as Skeleton } from '@/components/Skeleton'
import Logo from '@/assets/logo.svg?react'

import { useGetProjectDashboardTickets } from '@/home/queries'
import { withLoader } from '@/utils/hocs/withLoader'

export interface TicketsProps {}

const TicketsContent: React.FC<TicketsProps> = () => {
  const { t } = useTranslation('common', { keyPrefix: 'home' })
  const { projectId, teamspaceId } = useParams<{ teamspaceId: string; projectId: string }>()
  const { data: dashboard, refetch } = useGetProjectDashboardTickets(teamspaceId, projectId)

  return (
    <div className="flex flex-col items-center gap-2">
      {dashboard.tickets.length ? (
        dashboard.tickets.map((ticket) => (
          <Ticket
            key={ticket.id}
            number={ticket.ticket_id}
            title={ticket.title}
            id={ticket.id}
            priority={ticket.priority}
            status={ticket.status}
            refetch={refetch}
          />
        ))
      ) : (
        <div className="flex flex-col items-center gap-3 py-3">
          <Logo className="mx-auto h-16 w-16 stroke-wall-main-light dark:stroke-wall-main-dark" />
          <p className="text-center text-sm text-wall-main-light dark:text-wall-main-dark">
            <Trans t={t} i18nKey="noTasksText" components={{ br: <br /> }} />
          </p>
        </div>
      )}
    </div>
  )
}

export const Tickets = withLoader(
  TicketsContent,
  <div className="flex flex-col items-center gap-2">
    <Skeleton className="h-[52px] w-full bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />
    <Skeleton className="h-[52px] w-full bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />
    <Skeleton className="h-[52px] w-full bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />
  </div>,
)
export default Tickets
