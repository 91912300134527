import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import SprintSettings from '../SprintSettings'
import SprintCompletion from '../SprintCompletion'
import { Cog6ToothIcon, PlusCircleIcon } from '@heroicons/react/24/outline'

import { useToggleOpen } from '@/utils/hooks/useToggle'

import { useGetProject } from '@/projects/queries'

import { ColumnType, IColumn } from '@/projects/models/IProject'
import { UserType } from '@/auth/models/IUser'
import { useUpdateSprint } from '@/projects/queries/sprints'
import { SprintStatus } from '@/projects/models/ISprint'

export interface ColumnHeaderProps {
  column: IColumn
}

export const ColumnHeader: React.FC<ColumnHeaderProps> = ({ column }) => {
  const { t } = useTranslation('projects', { keyPrefix: 'sprint' })
  const { isOpen, toggleOpen } = useToggleOpen()
  const { isOpen: isSprintCompletionOpen, toggleOpen: toggleSprintCompletionOpen } = useToggleOpen()
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()

  const { title, type, id, cards, activeSprintUUID, isActiveSprint, color = '#33AED4' } = column
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: patchSprint } = useUpdateSprint(projectId, teamspaceId, column.id)

  if (type === ColumnType.newSprint) {
    return (
      <>
        <button
          className="mb-3 flex w-full items-center justify-center gap-1 rounded border bg-wall-secondary-bg-light px-3 py-2 text-wall-main-light transition-colors hover:bg-wall-main-bg-light dark:bg-wall-secondary-bg-dark dark:text-wall-main-dark dark:hover:bg-wall-main-bg-dark"
          onClick={toggleOpen}
        >
          {t('createLabel')} <PlusCircleIcon className="h-5 w-5" />
        </button>
        {isOpen && <SprintSettings id={id} isOpen={isOpen} handleClose={toggleOpen} />}{' '}
      </>
    )
  }

  const isBoardAndSprintIsActive = type === ColumnType.status && !!activeSprintUUID && project.sprints_enabled
  const isBacklogAndSprintIsActive = type === ColumnType.sprint && isActiveSprint

  return (
    <>
      <div
        className={cx('flex w-full justify-between rounded px-3 py-2')}
        style={{ backgroundImage: `linear-gradient(to right, ${color}, #33AED4)` }}
      >
        <p className="text-white">{title}: </p>
        <div className="flex gap-2">
          <div className="flex h-7 w-7 items-center justify-center rounded bg-cyan-700 px-2 font-medium text-white">
            {cards.filter(Boolean).length}
          </div>
          {!project.active_sprint_id && column.type === ColumnType.sprint && (
            <button
              className="flex h-7 items-center justify-center rounded bg-cyan-700 p-1 text-white hover:bg-cyan-600"
              onClick={() => {
                patchSprint({ status: SprintStatus.ACTIVE })
              }}
            >
              {t('startSprintLabel')}
            </button>
          )}
          {project.user_type === UserType.regular && type === ColumnType.sprint && (
            <>
              <button
                className="flex h-7 w-7 items-center justify-center rounded bg-cyan-700 p-1 text-white hover:bg-cyan-600"
                onClick={toggleOpen}
              >
                <Cog6ToothIcon className="h-4 w-4" />
              </button>
              <SprintSettings id={id} isOpen={isOpen} handleClose={toggleOpen} />
            </>
          )}
          {project.user_type === UserType.regular && (isBoardAndSprintIsActive || isBacklogAndSprintIsActive) && (
            <>
              <button
                className="flex h-7 items-center justify-center rounded bg-cyan-700 p-1 text-sm text-white hover:bg-cyan-600"
                onClick={toggleSprintCompletionOpen}
              >
                {t('completeSprintLabel')}
              </button>
              {isSprintCompletionOpen && (
                <SprintCompletion isOpen={isSprintCompletionOpen} handleClose={toggleSprintCompletionOpen} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ColumnHeader
