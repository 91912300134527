import React, { useMemo } from 'react'

import { FolderIcon } from '@heroicons/react/24/outline'
import Skeleton from '@/components/Skeleton'
import Select from '@/components/Select'

import { useGetAllProjects } from '@/projects/queries'
import { useTranslation } from 'react-i18next'

interface ProjectSelectorProps {
  value?: string
  onChange?: (status: string) => void
  disabled?: boolean
}
const ProjectSelector: React.FC<ProjectSelectorProps> = ({ value, onChange, disabled, ...other }) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.project' })
  const { data } = useGetAllProjects()
  const projects = data

  const options = useMemo(
    () =>
      projects
        ? projects
            .filter((p) => !p.deleted_at)
            .map((p) => ({
              value: p.id,
              label: p.name,
            }))
        : [],
    [projects],
  )

  return (
    <Skeleton isActive={!!projects} className="rounded-lg border border-gray-200 bg-gray-100">
      <Select
        value={value}
        onChange={onChange}
        options={options}
        disabled={disabled}
        Icon={<FolderIcon className="mr-2 h-4 w-4" />}
        title={t('title')}
        placeholder={t('title')}
        defaultColor=""
        {...other}
      />
    </Skeleton>
  )
}

export default ProjectSelector
