import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import cx from 'clsx'

import Invite from '@/projects/components/UserSelect/components/Invite'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { XMarkIcon } from '@heroicons/react/24/outline'

import { useDeleteUsers, useGetUserList } from '@/projects/queries'
import { getUserAbbr, getUserName } from '@/projects/components/UserSelect/hooks/useUserOptions'
import { useGetMe } from '@/auth/queries'
import { UserType } from '@/auth/models/IUser'
import { useIsWideScreen } from '@/utils/hooks/useIsWideScreen'

export interface UsersProps {}

export const Users: React.FC<UsersProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'users' })
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const isWide = useIsWideScreen()

  const { data: me } = useGetMe()
  const { data: users, refetch: refetchUsers } = useGetUserList(projectId, teamspaceId)
  const { mutateAsync: deleteUser, isPending } = useDeleteUsers(projectId, teamspaceId)

  const isFirstRef = useRef(false)

  useEffect(() => {
    if (!isFirstRef.current) {
      refetchUsers()
      isFirstRef.current = true
    }
  }, [])

  const [deleteUuid, setDeleteUuid] = useState<string | null>(null)
  const userToDeleteName = useMemo(() => {
    const user = users.find((user) => user.uuid === deleteUuid)

    return user ? getUserName(user.first_name, user.last_name) : null
  }, [deleteUuid])

  return (
    <div
      className={cx('-mt-3 flex flex-col gap-2 overflow-auto p-3', {
        'max-w-2xl': isWide,
      })}
    >
      <ConfirmationDialog
        onClose={() => setDeleteUuid(null)}
        isOpen={!!deleteUuid}
        isLoading={isPending}
        confirmationTitle={t('deleteConfirmationTitle')}
        confirmationText={t('deleteConfirmationText', {
          user: userToDeleteName,
        })}
        onConfirm={async () => {
          if (deleteUuid) {
            await deleteUser(deleteUuid)
            toast.success(
              t('deleteSuccessMessage', {
                user: userToDeleteName,
              }),
            )
            setDeleteUuid(null)
          }
        }}
      />
      {users.map((user) => (
        <div
          key={user.uuid}
          className="flex items-center justify-between gap-2 rounded bg-wall-secondary-bg-light p-2 dark:bg-wall-secondary-bg-dark"
        >
          <div className="flex items-center gap-2">
            <div className="flex h-7 w-7 items-center justify-center rounded-md bg-wall-main-light text-white dark:bg-wall-main-dark">
              {getUserAbbr(user.first_name, user.last_name)}
            </div>
            <div className="text-sm">{getUserName(user.first_name, user.last_name)}</div>
            <div className="rounded bg-wall-main-bg-light px-1 py-0.5 text-xs dark:bg-wall-main-bg-dark">
              {user.user_type === UserType.external ? t('externalLabel') : t('regularLabel')}
            </div>
          </div>
          {user.uuid !== me.uuid && (
            <button
              type="button"
              className="rounded transition-colors hover:bg-wall-main-bg-light hover:dark:bg-wall-main-bg-dark"
              onClick={() => setDeleteUuid(user.uuid)}
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      ))}
      <Invite onInvite={() => {}} />
    </div>
  )
}

export default Users
