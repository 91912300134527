import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'

import api from '@/utils/api'

import { IMoveTicketsRequest, ISprint, ISprintResponse } from '../models/ISprint'
import { KEYS as PROJECT_KEYS } from './index'

export const PATHS = {
  projectSprints: 'api/teamspaces/:teamspaceId/projects/:projectId/sprints/',
  projectSprint: 'api/teamspaces/:teamspaceId/projects/:projectId/sprints/:sprintId',
  moveTickets: 'api/teamspaces/:teamspaceId/projects/:projectId/sprints/move-tickets',
}

export const enum KEYS {
  sprints = 'sprints',
}

export const useGetSprints = (projectId?: string, teamspaceId?: string) => {
  return useSuspenseQuery<ISprintResponse[], AxiosError>({
    queryKey: [KEYS.sprints, projectId],
    queryFn: async () => {
      const response = await api.get<ISprintResponse[]>(
        PATHS.projectSprints.replace(':projectId', projectId || '').replace(':teamspaceId', teamspaceId || ''),
      )
      return response.data
    },
  })
}

export const useGetSprint = (projectId?: string, teamspaceId?: string, sprintId?: string) => {
  return useQuery<ISprintResponse, AxiosError>({
    queryKey: [KEYS.sprints, projectId, sprintId],
    enabled: !!projectId && !!teamspaceId && !!sprintId,
    queryFn: async () => {
      const response = await api.get<ISprintResponse>(
        PATHS.projectSprint
          .replace(':projectId', projectId || '')
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':sprintId', sprintId || ''),
      )
      return response.data
    },
  })
}

export const useCreateSprint = (projectId?: string, teamspaceId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<ISprintResponse, AxiosError, ISprint>({
    mutationFn: async (body) => {
      const response = await api.post<ISprintResponse>(
        PATHS.projectSprints.replace(':projectId', projectId || '').replace(':teamspaceId', teamspaceId || ''),
        body,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.sprints, projectId] })
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEYS.project, projectId] })
    },
  })
}

export const useUpdateSprint = (projectId?: string, teamspaceId?: string, sprintId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<ISprintResponse, AxiosError, Partial<ISprint>>({
    mutationFn: async (body) => {
      const response = await api.patch<ISprintResponse>(
        PATHS.projectSprint
          .replace(':projectId', projectId || '')
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':sprintId', sprintId || ''),
        body,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.sprints, projectId, sprintId] })
      queryClient.invalidateQueries({ queryKey: [KEYS.sprints, projectId] })
      queryClient.invalidateQueries({ queryKey: [PROJECT_KEYS.project, projectId] })
    },
  })
}

export const useDeleteSprint = (projectId?: string, teamspaceId?: string, sprintId?: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async () => {
      const response = await api.delete(
        PATHS.projectSprint
          .replace(':projectId', projectId || '')
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':sprintId', sprintId || ''),
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.sprints, projectId] })
    },
  })
}

export const useMoveTickets = (projectId?: string, teamspaceId?: string) => {
  const queryClient = useQueryClient()
  return useMutation<unknown, AxiosError, IMoveTicketsRequest>({
    mutationFn: async (body) => {
      const response = await api.post(
        PATHS.moveTickets.replace(':projectId', projectId || '').replace(':teamspaceId', teamspaceId || ''),
        body,
      )
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.sprints, projectId] })
    },
  })
}
