import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'
import cx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import Select from '@/components/Select'

import { Button, Drawer } from 'flowbite-react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { CheckIcon, PencilIcon } from '@heroicons/react/24/solid'
import Status from '@/components/Status'

import { useDeleteStatus, useGetProject, useUpdateProject } from '@/projects/queries'
import { useStatusCategoryOptions } from '../../constants'

import { IStatusRequest, IStatusResponse, StatusCategory } from '@/projects/models/IProject'
import { toast } from 'react-toastify'
import { useIsIos } from '@/utils/hooks/useIsMobileDevice'
import { useGetMe } from '@/auth/queries'
import { sendEvent } from '@/utils/hooks/useAmplitude'

export interface CardProps {
  card: IStatusResponse
  ownerId?: string
  className?: string
  isAdding?: boolean
  isOverlay?: boolean
  onSave?: (card?: IStatusResponse & { id: string }) => void
}

type Inputs = {
  name: string
  category: StatusCategory
  moveToStatus: string
}

const MAX_TITLE_LENGTH = 70

const Card: React.FC<CardProps> = ({ card, className, isAdding = false, isOverlay = false, onSave, ownerId }) => {
  const { t } = useTranslation('projects', { keyPrefix: 'settings.statuses' })
  const isIos = useIsIos()
  const { data: me } = useGetMe()

  const statusCategoryOptions = useStatusCategoryOptions()

  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const { attributes, listeners, setNodeRef, transform, isDragging, isSorting } = useSortable({
    id: card.id,
  })
  let transformStyle = CSS.Transform.toString(transform)

  transformStyle = isDragging ? CSS.Transform.toString(transform) : transformStyle

  const style = {
    transform: transformStyle,
  }

  const { mutateAsync: update } = useUpdateProject(projectId, teamspaceId)
  const { data: project } = useGetProject(projectId, teamspaceId)
  const { mutateAsync: deleteStatus, data: deleteStatusData } = useDeleteStatus(projectId, teamspaceId)

  const [isEdit, setIsEdit] = useState(isAdding || false)
  const [showDeleteConfiration, setShowDeleteConfiration] = useState(false)

  const { watch, register, setValue, handleSubmit, setFocus } = useForm<Inputs>({
    defaultValues: {
      name: card.name,
      category: card.category,
      moveToStatus: project.settings.statuses.filter((status) => status.id !== card.id)[0]?.id,
    },
  })
  const category = watch('category')
  const moveToStatus = watch('moveToStatus')

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    let newStatuses: IStatusRequest[] = [...(project?.settings.statuses || [])]
    const newPosition = project?.settings.statuses.length
    if (isAdding) {
      newStatuses.push({
        position: project?.settings.statuses.length,
        category: data.category,
        name: data.name,
      })
      sendEvent('create-column')
    } else {
      newStatuses = newStatuses.map((status) => {
        if (status.id === card.id) {
          return { ...status, name: data.name, category: data.category }
        }
        return status
      })
      sendEvent('update-column')
      setIsEdit(false)
    }

    const response = await update({
      settings: {
        ...project.settings,
        statuses: newStatuses,
      },
    })
    const newElement = response.settings.statuses.find((status) => status.position === newPosition)
    if (newElement) {
      onSave?.({ ...newElement, id: newElement.id })
    }
  }

  const handleDelete = async () => {
    deleteStatus({
      status_id: card.id,
      safe: false,
      change_to_status_id: moveToStatus,
    })
    sendEvent('delete-column')

    setShowDeleteConfiration(false)
    setIsEdit(false)
  }

  const categoryOption = statusCategoryOptions.find((option) => option.value === category)

  const handleDeleteClick = () => {
    const hasOtherStatusesWithSameCategory = project.settings.statuses.some(
      (status) => status.category === card.category && status.id !== card.id,
    )
    if (!hasOtherStatusesWithSameCategory) {
      toast(
        <Trans
          t={t}
          i18nKey="lastStatusRemoveWarning"
          components={{ category: <span style={{ color: categoryOption?.color }} /> }}
          values={{ category: categoryOption?.label }}
        />,
        { type: 'warning' },
      )
      return
    }
    if (isAdding) {
      onSave?.()
      return
    }
    deleteStatus({ safe: true, status_id: card.id })
    setShowDeleteConfiration(true)
  }
  const ref = useRef<HTMLDivElement>(null)
  const props = isEdit
    ? {}
    : {
        ref: setNodeRef,
        ...attributes,
        ...listeners,
      }

  useEffect(() => {
    if (isEdit) {
      setTimeout(() => {
        setFocus('name')
      }, 100)
    }
  }, [isEdit])

  return (
    <form
      {...(me.uuid === ownerId ? props : {})}
      className={cx(
        'relative flex touch-manipulation items-center justify-between gap-2 rounded bg-wall-main-bg-light px-3 py-2 text-sm shadow dark:bg-wall-main-bg-dark',
        className,
        {
          'opacity-50': isDragging,
          'py-[13px]': isOverlay,
        },
      )}
      style={{ ...style }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="absolute left-0 h-full w-1 rounded-l" style={{ backgroundColor: categoryOption?.color }} />
      {isEdit ? (
        <div className="relative" ref={ref}>
          <input {...register('name')} maxLength={MAX_TITLE_LENGTH} className="w-full bg-transparent py-1 pl-6" />
          <PencilIcon className="absolute left-1 top-2 h-3 w-3" />
        </div>
      ) : (
        <p>{watch('name')}</p>
      )}
      <div className="flex items-center gap-2">
        {isEdit && (
          <Select
            options={statusCategoryOptions}
            onChange={(value) => {
              const hasOtherStatusesWithSameCategory = project.settings.statuses.some(
                (status) => status.category === category && status.id !== card.id,
              )
              if (!hasOtherStatusesWithSameCategory) {
                toast(
                  <Trans
                    t={t}
                    i18nKey="lastStatusRemoveWarning"
                    components={{ category: <span style={{ color: categoryOption?.color }} /> }}
                    values={{ category: categoryOption?.label }}
                  />,
                  { type: 'warning' },
                )
                return
              }
              setValue('category', value)
            }}
            value={category}
            title={t('selectCategoryLabel')}
          />
        )}
        {isEdit && (
          <button
            className="rounded border border-wall-secondary bg-wall-secondary-bg-light p-1.5 dark:bg-wall-secondary-bg-dark"
            type="button"
            onClick={handleDeleteClick}
          >
            <TrashIcon className="h-4 w-4" />
          </button>
        )}

        {ownerId === me?.uuid && (
          <button
            className={cx(
              'rounded border bg-wall-secondary-bg-light p-1.5 dark:bg-wall-secondary-bg-dark',
              isEdit ? 'border-wall-green-dark' : 'dark:border-gray-600',
            )}
            type={isEdit ? 'submit' : 'button'}
            onClick={
              !isEdit
                ? (e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setIsEdit(true)
                  }
                : undefined
            }
          >
            {isEdit ? <CheckIcon className="h-4 w-4" /> : <PencilIcon className="h-4 w-4" />}
          </button>
        )}

        {!isOverlay && !isDragging && !isSorting && (
          <Drawer
            open={showDeleteConfiration}
            position="bottom"
            onClose={() => setShowDeleteConfiration(false)}
            className={cx({ 'pb-10': isIos })}
          >
            <Drawer.Header title={t('deleteLabel', { status: card.name })} titleIcon={() => null} className="pr-4" />
            <Drawer.Items className="flex flex-col gap-4">
              {(deleteStatusData?.ticket_count || 0) > 0 && (
                <div className="flex items-center gap-2">
                  <p>{t('moveToLabel')}</p>
                  <Status
                    filter={({ value }) => value !== card.id}
                    status={moveToStatus}
                    onChange={(status) => setValue('moveToStatus', status)}
                  />
                </div>
              )}
              <div className="flex justify-end gap-2">
                <Button size="xs" color="red" onClick={() => setShowDeleteConfiration(false)}>
                  {t('cancelDeleteLabel')}
                </Button>
                <Button size="xs" color="green" onClick={handleDelete}>
                  {t('confirmDeleteLabel')}
                </Button>
              </div>
            </Drawer.Items>
          </Drawer>
        )}
      </div>
    </form>
  )
}

export default Card
