import { useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { DndContext, DragEndEvent, DragOverlay, Modifier } from '@dnd-kit/core'
import { useParams } from 'react-router-dom'
import { Spinner } from 'flowbite-react'
import { useAtom, useAtomValue } from 'jotai'

import Card from './components/Card'
import Columns from './components/Columns'
import AddNewTicket from '@/components/AddNewTicket'

import { useGetProject, usePatchTicket } from '@/projects/queries'
import useDnDHelpers from '@/utils/hooks/useDnDHelpers'
import { columns as columnsAtom, handleTicketDrop as handleTicketDropAtom } from '@/projects/store'
import { useIsWideScreen } from '@/utils/hooks/useIsWideScreen'

import { ICard, IColumn, IProjectResponse } from '@/projects/models/IProject'
import { UserType } from '@/auth/models/IUser'

const COLUMN_HEIGHT = 44
const GAP_BETWEEN_ITEMS = 12
const CREATE_CARD_HEIGHT = 40

export interface BoardProps {
  isLoading: boolean
}

const positionModifier: (
  column: number,
  offsetTop: number,
  cardTopOffset: number,
  project: IProjectResponse,
) => Modifier = (column, offsetTop, cardTopOffset, project) => (args) => {
  const { transform } = args

  const addedHeight = offsetTop + column * (COLUMN_HEIGHT + GAP_BETWEEN_ITEMS * 2)
  const removedHeight = project.user_type === UserType.regular ? cardTopOffset + CREATE_CARD_HEIGHT : 0
  return {
    ...transform,
    y: transform.y - addedHeight + removedHeight,
  }
}

const Board: React.FC<BoardProps> = ({ isLoading }) => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()

  const isWide = useIsWideScreen()

  const { mutateAsync: patchTicket } = usePatchTicket(teamspaceId, projectId)
  const { data: project } = useGetProject(projectId, teamspaceId)

  const [columns, setColumns] = useAtom(columnsAtom)
  const handleTicketDrop = useAtomValue(handleTicketDropAtom)

  const { sensors, activeElement, handleDragEnd, handleDragOver, handleDragStart } = useDnDHelpers<ICard, IColumn>({
    columns,
    setColumns,
    dropTop: true,
  })

  const modifiers = useMemo(() => {
    return isWide
      ? []
      : [
          positionModifier(
            activeElement?.columnNumber || 0,
            activeElement?.scrollTop || 0,
            activeElement?.cardTopOffset || 0,
            project,
          ),
        ]
  }, [isWide, activeElement])

  const handleDragEndAndUpdate = useCallback(
    async (event: DragEndEvent) => {
      handleDragEnd(event)
      if (handleTicketDrop) {
        handleTicketDrop(event.active.id as string, event.active.data.current?.sortable.containerId as string)
      } else {
        patchTicket({
          id: event.active.id as string,
          status: event.active.data.current?.sortable.containerId as string,
        })
      }
    },
    [handleTicketDrop],
  )

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEndAndUpdate}
        onDragOver={handleDragOver}
      >
        <Columns columns={columns} isWide={isWide} id={projectId || ''} isLoading={isLoading} />
        {isLoading && (
          <div className="flex h-full w-full justify-center">
            <Spinner className="h-6 w-6" />
          </div>
        )}
        {createPortal(
          <DragOverlay modifiers={modifiers}>
            {activeElement && (
              <Card
                card={activeElement}
                className="rotate-6 select-none dark:text-neutral-200"
                isOverlay
                isWide={isWide}
              />
            )}
          </DragOverlay>,
          document.body,
        )}
        {project.user_type === UserType.regular && <AddNewTicket />}
      </DndContext>
    </>
  )
}

export default Board
