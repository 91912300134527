import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useState } from 'react'

import Card from '../Card'
import { PlusCircleIcon } from '@heroicons/react/24/outline'

import { useDroppable } from '@dnd-kit/core'

import { IStatusResponse, StatusCategory } from '@/projects/models/IProject'
import { useGetMe } from '@/auth/queries'

export type GroupType = {
  id: string
  ownerId?: string
  title?: string
  color?: string
  cards: (IStatusResponse & { id: string })[]
  onAddStatus: (card: IStatusResponse & { id: string }) => void
}

const newStatus: IStatusResponse & { id: string } = {
  name: '',
  position: 0,
  category: StatusCategory.Todo,
  id: 'new',
}

const Group: React.FC<GroupType> = ({ id, ownerId, title, cards, color, onAddStatus }) => {
  const { setNodeRef, active } = useDroppable({ id })
  const { data: me } = useGetMe()

  const [isAdding, setIsAdding] = useState(false)

  return (
    <SortableContext id={id} items={cards} strategy={rectSortingStrategy}>
      <div
        ref={setNodeRef}
        className={twMerge(
          cx(
            'touch-none rounded border border-transparent bg-wall-secondary-bg-light transition-[border-color] dark:bg-wall-secondary-bg-dark',
            {
              'border-blue-500': active?.data.current?.sortable?.containerId === id,
            },
          ),
        )}
      >
        <div className="flex select-none flex-col gap-1 overflow-auto p-1">
          {title && (
            <p className="text-sm font-medium uppercase" style={{ color: color || 'inherit' }}>
              {title}
            </p>
          )}
          {cards.map((card) => (
            <Card key={card.id} card={card} ownerId={ownerId} />
          ))}
          {isAdding && (
            <Card
              key="new"
              card={newStatus}
              isAdding
              ownerId={ownerId}
              onSave={(status?: IStatusResponse & { id: string }) => {
                setIsAdding(false)
                if (status) {
                  onAddStatus?.(status)
                }
              }}
            />
          )}
          {!isAdding && me.uuid === ownerId && (
            <button
              onClick={() => setIsAdding(true)}
              className="flex items-center justify-center rounded bg-wall-main-bg-light px-3 py-1 shadow dark:bg-wall-main-bg-dark"
            >
              <PlusCircleIcon className="h-6 w-6" />
            </button>
          )}
        </div>
      </div>
    </SortableContext>
  )
}

export default Group
