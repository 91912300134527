import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { initData } from '@telegram-apps/sdk-react'

import commonEN from './locales/en/common.json'
import projectsEN from './locales/en/projects.json'

import commonRU from './locales/ru/common.json'
import projectsRU from './locales/ru/projects.json'

import commonUA from './locales/ua/common.json'
import projectsUA from './locales/ua/projects.json'

import commonPT from './locales/pt/common.json'
import projectsPT from './locales/pt/projects.json'

export enum Language {
  EN = 'en',
  RU = 'ru',
  UK = 'uk',
  PT = 'pt',
}

export const defaultNS = 'common'
export const resources = {
  [Language.EN]: {
    common: commonEN,
    projects: projectsEN,
  },
  [Language.RU]: {
    common: commonRU,
    projects: projectsRU,
  },
  [Language.UK]: {
    common: commonUA,
    projects: projectsUA,
  },
  [Language.PT]: {
    common: commonPT,
    projects: projectsPT,
  },
}

i18next.use(initReactI18next).init({
  lng: initData?.user()?.language_code || Language.EN,
  fallbackLng: Language.EN,
  resources,
  defaultNS,
})
