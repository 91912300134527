import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'

import {
  ClipboardDocumentIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { Drawer, Textarea } from 'flowbite-react'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'

import {
  useGetComments,
  useCreateComment,
  useUpdateComment,
  useDeleteComment,
  useGetUserList,
} from '@/projects/queries'
import { useEffect, useRef, useState } from 'react'
import { useGetMe } from '@/auth/queries'
import { useToggleOpen } from '@/utils/hooks/useToggle'
import { ICommentListResponse } from '@/projects/models/IComment'
import { hapticFeedback } from '@telegram-apps/sdk-react'
import { useIsIos } from '@/utils/hooks/useIsMobileDevice'
import { withLoader } from '@/utils/hocs/withLoader'
import Skeleton from '@/components/Skeleton'

export interface CommentsProps {}

type Inputs = {
  message: string
  editId: string | null
}

const LONG_PRESS_DURATION = 500
const MAX_MESSAGE_LENGTH = 4096

const getSortedComments = (comments?: ICommentListResponse) => {
  return comments?.sort((a, b) => dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf()) || []
}

const CommentsComponent: React.FC<CommentsProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.comments' })
  const { data: user } = useGetMe()
  const { ticketId, projectId, teamspaceId } = useParams<{ ticketId: string; projectId: string; teamspaceId: string }>()
  const isIos = useIsIos()

  const topRef = useRef<HTMLDivElement>(null)
  const touchStartTimeRef = useRef<number | null>(null)
  const touchTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [contextMenuId, setContextMenuId] = useState<string | null>(null)
  const { isOpen: isDeleteOpen, toggleOpen: toggleDelete } = useToggleOpen()

  const { data: comments } = useGetComments(projectId, teamspaceId, ticketId)
  const { mutateAsync: createComment, isPending: isCreating } = useCreateComment(projectId, teamspaceId, ticketId)
  const { mutateAsync: updateComment, isPending: isUpdating } = useUpdateComment(projectId, teamspaceId, ticketId)
  const { mutateAsync: deleteComment } = useDeleteComment(projectId, teamspaceId, ticketId)
  const { data: users } = useGetUserList(projectId, teamspaceId)

  const [commentsList, setCommentsList] = useState(getSortedComments(comments))

  useEffect(() => {
    if (comments) {
      setCommentsList(getSortedComments(comments))
    }
  }, [comments])

  const isPending = isCreating || isUpdating
  const { register, handleSubmit, reset, setValue, setFocus, watch } = useForm<Inputs>({
    defaultValues: { message: '', editId: null },
  })

  const onSubmit = async (data: Inputs) => {
    if (data.editId) {
      await updateComment({ content: data.message, commentId: data.editId })
      reset()
    } else {
      setCommentsList((comments) => {
        const newComments: ICommentListResponse = [
          {
            content: data.message,
            created_at: dayjs().toISOString(),
            ticket_id: '',
            user_id: user?.uuid,
            updated_at: dayjs().toISOString(),
            uuid: 'new',
          },
          ...comments,
        ]

        return newComments
      })
      reset()
      await createComment({ content: data.message })
      topRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const handleDelete = async () => {
    deleteComment(contextMenuId as string)
    toggleDelete()
    setContextMenuId(null)
    topRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleTouchStart = (id: string) => (e: React.TouchEvent) => {
    e.preventDefault()
    touchStartTimeRef.current = Date.now()
    touchTimeoutRef.current = setTimeout(() => {
      hapticFeedback.notificationOccurred('success')
      setContextMenuId(id)
    }, LONG_PRESS_DURATION)
  }

  const handleTouchEnd = () => {
    if (touchTimeoutRef.current) {
      clearTimeout(touchTimeoutRef.current)
    }
  }

  return (
    <>
      <div className={cx('relative h-[calc(100%-40px)] overflow-hidden', { isIos: 'select-none' })}>
        <form onSubmit={handleSubmit(onSubmit)} className="top-13 fixed left-0 z-10 w-full px-3">
          <Textarea
            placeholder={t('addPlaceholder')}
            {...register('message')}
            className="resize-none"
            maxLength={MAX_MESSAGE_LENGTH}
            rows={5}
          />
          <button
            className="absolute right-5 top-2 text-wall-main-light disabled:opacity-50 dark:text-wall-main-dark"
            disabled={isPending || !watch('message').length}
          >
            <PaperAirplaneIcon className="h-5 w-5" />
          </button>
        </form>
        <div className="flex h-full select-none flex-col gap-2 overflow-y-auto pt-[134px]" ref={topRef}>
          {!commentsList.length && <div className="text-center text-wall-secondary">{t('noMessages')}</div>}
          {commentsList.map((comment) => {
            const user = users?.find((user) => user.uuid === comment.user_id)
            const name = [user?.first_name, user?.last_name].filter(Boolean).join(' ')
            return (
              <div
                key={comment.uuid}
                onTouchStart={handleTouchStart(comment.uuid)}
                onTouchEnd={handleTouchEnd}
                className="flex w-full justify-between whitespace-pre-wrap rounded bg-wall-secondary-bg-light p-2 dark:bg-wall-secondary-bg-dark"
              >
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-1">
                    <div className="h-6 w-6 rounded bg-wall-main-light p-1 text-center text-xs font-medium text-white dark:bg-wall-main-dark">
                      {user?.first_name?.[0]}
                    </div>
                    <div className="flex items-end gap-2">
                      <div className="text-sm">{name}</div>
                      <div className="text-xs text-gray-400">{dayjs(comment.created_at).format('MMM D, YYYY')}</div>
                    </div>
                  </div>
                  {comment.content}
                </div>
                <div className="flex items-start gap-1">
                  {user?.uuid === comment.user_id && (
                    <button type="button" onClick={() => setContextMenuId(comment.uuid)}>
                      <EllipsisVerticalIcon className="h-4 w-4 text-gray-400" />
                    </button>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Drawer
        open={!!contextMenuId}
        position="bottom"
        className={cx('rounded-t', { 'select-none pb-10': isIos })}
        onClose={() => setContextMenuId(null)}
      >
        <div className="w-full pb-3">
          <div className="relative line-clamp-5 whitespace-pre-wrap pr-6 indent-7 align-middle">
            {comments?.find((c) => c.uuid === contextMenuId)?.content}
            <button
              type="button"
              className="absolute left-0 top-0 z-10 rounded text-wall-main-light hover:bg-slate-100 dark:text-wall-main-dark dark:hover:bg-slate-700"
              onClick={async () => {
                await navigator.clipboard.writeText(comments?.find((c) => c.uuid === contextMenuId)?.content || '')
                hapticFeedback.notificationOccurred('success')
              }}
            >
              <ClipboardDocumentIcon className="h-6 w-6" />
            </button>
          </div>
          <button
            type="button"
            className="absolute right-2.5 top-2.5 rounded p-2 hover:bg-slate-100 dark:hover:bg-slate-700"
            onClick={() => setContextMenuId(null)}
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
        <Drawer.Items className="flex flex-col gap-2">
          <button
            type="button"
            onClick={() => {
              setValue('editId', contextMenuId)
              setValue('message', comments?.find((c) => c.uuid === contextMenuId)?.content || '')
              setFocus('message')
              setContextMenuId(null)
            }}
            className="flex items-center gap-2 rounded bg-wall-main-bg-light px-2 py-1.5 text-sm dark:bg-wall-main-bg-dark"
          >
            <PencilIcon className="h-4 w-4" />
            {t('editLabel')}
          </button>
          <button
            type="button"
            className="flex items-center gap-2 rounded bg-wall-main-bg-light px-2 py-1.5 text-sm dark:bg-wall-main-bg-dark"
            onClick={toggleDelete}
          >
            <TrashIcon className="h-4 w-4" />
            {t('deleteLabel')}
          </button>
        </Drawer.Items>
      </Drawer>
      <ConfirmationDialog
        isOpen={isDeleteOpen}
        onClose={toggleDelete}
        onConfirm={handleDelete}
        confirmationTitle={t('deleteConfirmationText')}
        isLoading={isPending}
      />
    </>
  )
}

const Comments = withLoader(CommentsComponent, <Skeleton isActive className="h-full w-full" />)
export default Comments
