import { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import cx from 'clsx'
import { twMerge } from 'tailwind-merge'

export interface TabItem {
  label: string
  to: string
}
export interface TabsProps {
  items: TabItem[]
}

const Tabs: React.FC<TabsProps> = ({ items }) => {
  const scrollRef = useRef<HTMLUListElement>(null)
  const [showLeftFade, setShowLeftFade] = useState(false)
  const [showRightFade, setShowRightFade] = useState(false)

  useEffect(() => {
    const el = scrollRef.current
    function updateFades() {
      if (!el) return
      const { scrollLeft, scrollWidth, clientWidth } = el
      setShowLeftFade(scrollLeft > 10)
      setShowRightFade(scrollLeft + clientWidth < scrollWidth)
    }

    if (el) {
      updateFades()

      el.addEventListener('scroll', updateFades)
      window.addEventListener('resize', updateFades)
      return () => {
        el.removeEventListener('scroll', updateFades)
        window.removeEventListener('resize', updateFades)
      }
    }
  }, [])

  return (
    <div className="relative border-b border-gray-200 px-0 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
      <ul className="no-scrollbar -mb-px flex overflow-x-auto px-3" ref={scrollRef}>
        {items.map((item) => (
          <li className="me-1 first-of-type:ms-1" key={item.to}>
            <NavLink
              to={item.to}
              className={({ isActive }) =>
                twMerge(
                  cx('inline-block whitespace-nowrap border-b-2 border-transparent px-4 py-2', {
                    'active border-b-2 border-wall-main-light text-wall-main-light dark:border-wall-main-dark dark:text-wall-main-dark':
                      isActive,
                  }),
                )
              }
              onClick={(e) => {
                e.currentTarget.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
      <div
        className={`pointer-events-none absolute bottom-0 left-0 top-0 w-8 bg-gradient-to-r from-wall-secondary-bg-light to-transparent transition-opacity duration-300 dark:from-wall-secondary-bg-dark ${showLeftFade ? 'opacity-100' : 'opacity-0'} `}
      />

      <div
        className={`pointer-events-none absolute bottom-0 right-0 top-0 w-8 bg-gradient-to-l from-wall-secondary-bg-light to-transparent transition-opacity duration-300 dark:from-wall-secondary-bg-dark ${showRightFade ? 'opacity-100' : 'opacity-0'} `}
      />
    </div>
  )
}

export default Tabs
