import { generatePath, Link, useParams } from 'react-router-dom'

import Priority from '@/components/Priority'

import { usePatchTicket } from '@/projects/queries'

import { Priority as PriorityEnum } from '@/projects/models/IProject'
import routes from '@/utils/routes'
import Status from '@/components/Status'

export interface TicketProps {
  title: string
  id: string
  number: string
  priority: PriorityEnum
  status: string
  refetch?: () => void
}

export const Ticket: React.FC<TicketProps> = ({ title, id, number, priority, status, refetch }) => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const { mutateAsync: patchTicket } = usePatchTicket(teamspaceId, projectId, id)
  return (
    <Link
      className="flex w-full items-start justify-between rounded bg-wall-secondary-bg-light p-2 text-sm dark:bg-wall-secondary-bg-dark"
      to={generatePath(routes.private.ticket, { teamspaceId, projectId, ticketId: id })}
      state={{ from: location.pathname }}
    >
      <div className="flex flex-col items-start gap-1">
        <p className="text-xs text-wall-main-light dark:text-wall-main-dark">{number}</p>
        <p className="text-xs">{title}</p>
      </div>
      <div className="flex gap-1">
        <Status
          status={status}
          onChange={async (value) => {
            await patchTicket({ status: value })
            refetch?.()
          }}
        />
        <Priority
          priority={priority}
          onChange={async (value) => {
            await patchTicket({ priority: value })
            refetch?.()
          }}
        />
      </div>
    </Link>
  )
}

export default Ticket
