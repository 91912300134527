import { AxiosError } from 'axios'
import { useSuspenseQuery } from '@tanstack/react-query'

import api from '@/utils/api'
import { IDashboardArticles, IDashboardTickets } from '../models/IDashboard'

export const enum KEYS {
  articles = 'articles',
  tickets = 'tickets',
}

export const PATHS = {
  projectDashboardTickets: 'api/teamspaces/:teamspaceId/projects/:projectId/dashboard/tickets',
  projectDashboardArticles: 'api/teamspaces/:teamspaceId/projects/:projectId/dashboard/articles',
}

export const useGetProjectDashboardArticles = (teamspaceId?: string, projectId?: string) => {
  return useSuspenseQuery<IDashboardArticles, AxiosError>({
    queryKey: [KEYS.articles, teamspaceId, projectId],
    queryFn: async () => {
      const response = await api.get<IDashboardArticles>(
        PATHS.projectDashboardArticles
          .replace(':teamspaceId', teamspaceId || '')
          .replace(':projectId', projectId || ''),
      )
      return response.data
    },
  })
}

export const useGetProjectDashboardTickets = (teamspaceId?: string, projectId?: string) => {
  return useSuspenseQuery<IDashboardTickets, AxiosError>({
    queryKey: [KEYS.tickets, teamspaceId, projectId],
    // refetchOnMount: true,
    staleTime: 0,
    queryFn: async () => {
      const response = await api.get<IDashboardTickets>(
        PATHS.projectDashboardTickets.replace(':teamspaceId', teamspaceId || '').replace(':projectId', projectId || ''),
      )
      return response.data
    },
  })
}
