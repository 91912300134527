export enum SprintStatus {
  ACTIVE = 'active',
  CREATED = 'created',
  COMPLETED = 'completed',
}

export interface ISprint {
  // id: string
  name: string
  goal: string
  start_date: string
  end_date: string
  status: SprintStatus
}

export interface ISprintResponse extends ISprint {
  id: string
  sprint_id: number
  project_id: string
}

export interface IMoveTicketsRequest {
  source_sprint_id: string
  target_sprint_id?: string
  to_backlog?: boolean
  status_ids: string[]
}
