import { ButtonHTMLAttributes, DetailedHTMLProps, useEffect, useMemo, useState } from 'react'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Invite from './components/Invite'
import Select from '@/components/Select'
import Skeleton from '@/components/Skeleton'
import { UserIcon } from '@heroicons/react/24/outline'

import { useIsIos } from '@/utils/hooks/useIsMobileDevice'
import { withLoader } from '@/utils/hocs/withLoader'
import { useUserOptions } from './hooks/useUserOptions'
import { useGetUserList } from '@/projects/queries'

export interface UserSelectProps {
  projectId?: string
  disabled?: boolean
  value?: string | null
  ticketId?: string
  onSelect?: (uuid: string | null) => void
  setInviteUuid?: (uuid: string) => void
}

export const UserSelectComponent: React.FC<UserSelectProps> = ({
  projectId: projectIdProps,
  disabled,
  value,
  onSelect,
}) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.assignee' })
  const isIos = useIsIos()
  const { projectId: projectIdParams, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const projectId = projectIdProps || projectIdParams
  const [selectedUser, setSelectedUser] = useState<string | null>(value || null)
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false)

  const baseOptions = useUserOptions({ projectId: projectIdProps })
  const { refetch: refetchUsers } = useGetUserList(projectId, teamspaceId)

  useEffect(() => {
    if (isSelectOpen) {
      refetchUsers()
    }
  }, [isSelectOpen])

  useEffect(() => {
    setSelectedUser(value || null)
  }, [value])

  const options = useMemo(() => {
    const options = [...baseOptions]
    options.push({
      value: 'unassigned',
      label: t('unassignedLabel'),
      element: (
        <div className="flex w-full items-center gap-2">
          <div className="flex h-7 w-7 items-center justify-center rounded-md bg-wall-main-light p-1 text-white dark:bg-wall-main-dark hover:dark:text-wall-main-dark">
            <UserIcon className="h-6 w-6" />
          </div>
          {t('unassignedLabel')}
        </div>
      ),
    })
    options.unshift({
      value: 'add_new_user',
      label: t('addNewLabel'),
      element: (
        <>
          <Invite onInvite={() => setIsSelectOpen(false)} />
          <span className="text-sm text-wall-gray-neutral">{t('selectFromListLabel')}</span>
        </>
      ),
      clickable: false,
    })
    return options
  }, [baseOptions, projectIdProps])

  const option = options.find((option) => option.value === selectedUser)
  const handleFocus = () => {
    setIsSelectOpen(true)
  }

  const handleBlur = () => {
    setIsSelectOpen(false)
  }

  const UserButton = useMemo(
    () => (props: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
      return (
        <button
          type="button"
          title={option?.label}
          className="flex h-7 w-7 items-center justify-center rounded-md bg-wall-main-light text-white transition-colors hover:bg-wall-main-light/80 dark:bg-wall-main-dark hover:dark:bg-wall-main-dark/80"
          {...props}
        >
          {selectedUser ? option?.shortLabel : <UserIcon className="h-4 w-4" />}
        </button>
      )
    },
    [selectedUser],
  )

  return (
    <Select
      options={options}
      disabled={disabled}
      Button={UserButton}
      title={t('title')}
      drawerClassName={cx('transition-height', isIos && 'h-[500px]')}
      onFocus={handleFocus}
      onBlur={handleBlur}
      open={isSelectOpen}
      onChange={(assignee) => {
        if (assignee === 'add_new_user') {
          return
        }
        if (assignee === 'unassigned') {
          setSelectedUser(null)
          onSelect?.(null)
          return
        }

        setSelectedUser(assignee)
        onSelect?.(assignee)
      }}
      value={value || 'unassigned'}
    />
  )
}

const UserSelect = withLoader(UserSelectComponent, <Skeleton isActive className="w-7" />)

export default UserSelect
