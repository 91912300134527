import { Option } from '@/components/Select'
import { useGetUserList } from '@/projects/queries'
import { useParams } from 'react-router-dom'

export const getUserName = (firstName: string | null, lastName: string | null) =>
  firstName && lastName ? `${firstName} ${lastName}` : (firstName ?? lastName ?? '')

export const getUserAbbr = (firstName: string | null, lastName: string | null) =>
  firstName && lastName ? `${firstName[0]}${lastName[0]}` : (firstName?.[0] ?? lastName?.[0] ?? '')

export const useUserOptions = (params?: { projectId?: string }) => {
  const { projectId: projectIdProps } = params || {}
  const { projectId: projectIdParams, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const projectId = projectIdProps || projectIdParams
  const { data: users } = useGetUserList(projectId, teamspaceId)
  const options = users.map<Option>((user) => ({
    value: user.uuid,
    label: getUserName(user.first_name, user.last_name),
    shortLabel: getUserAbbr(user.first_name, user.last_name),
    element: (
      <div className="flex w-full items-center gap-2 rounded">
        <div className="flex h-7 w-7 items-center justify-center rounded-md bg-wall-main-light text-white dark:bg-wall-main-dark">
          {getUserAbbr(user.first_name, user.last_name)}
        </div>
        <div className="text-sm">{getUserName(user.first_name, user.last_name)}</div>
      </div>
    ),
  }))

  return options
}
