import React, { Suspense, useMemo } from 'react'
import { generatePath, useParams, Outlet, Navigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'

import TabMenu, { TabItem } from '@/components/TabMenu'

import routes from '@/utils/routes'
import { useGetProject, useGetProjects } from '@/projects/queries'
import { useGetMe } from '@/auth/queries'
import { project } from '@/projects/store'

export interface ProjectProps {}

export const Project: React.FC<ProjectProps> = () => {
  const { t } = useTranslation('projects', { keyPrefix: 'tabs' })

  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const { data: projects } = useGetProjects(teamspaceId)
  const { data: me } = useGetMe()

  const [selectedProject] = useAtom(project)

  const { data: projectData } = useGetProject(selectedProject || projects?.[0]?.id, teamspaceId)

  const tabs = useMemo<TabItem[]>(
    () =>
      [
        {
          label: t('board'),
          to: routes.private.board,
        },
        {
          label: t('backlog'),
          to: routes.private.projectBacklog,
        },
        {
          label: t('settings'),
          to: routes.private.projectSettings,
        },
        {
          label: t('columns'),
          to: routes.private.projectColumns,
        },
        {
          label: t('users'),
          to: routes.private.projectUsers,
        },
        {
          label: t('recurring'),
          to: routes.private.recurring,
        },
      ]
        .filter((tab) => {
          if ([routes.private.projectColumns, routes.private.projectUsers].includes(tab.to)) {
            return projectData.owner_id === me?.uuid
          }
          if (tab.to === routes.private.projectBacklog) {
            return projectData.backlog_enabled
          }
          return true
        })
        .map((tab) => ({
          ...tab,
          to: generatePath(tab.to, { projectId: selectedProject || projects?.[0]?.id || '', teamspaceId }),
        })),
    [projectId, projects, t, me, projectData],
  )

  if (!projectId) {
    return (
      <Navigate
        to={generatePath(routes.private.board, { teamspaceId, projectId: selectedProject || projects?.[0]?.id })}
      />
    )
  }

  return (
    <>
      <div>
        <TabMenu items={tabs} />
      </div>
      <Suspense
        fallback={<div className="h-full w-full rounded bg-wall-main-bg-light dark:bg-wall-secondary-bg-dark" />}
      >
        <Outlet />
      </Suspense>
    </>
  )
}

export default Project
