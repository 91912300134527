import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Select, { Option } from '@/components/Select'
import Skeleton from '../Skeleton'

import { useStatusCategoryOptions } from '@/projects/pages/Statuses/constants'
import { useGetProject } from '@/projects/queries'
import { withLoader } from '@/utils/hocs/withLoader'

export interface StatusProps {
  status?: string
  disabled?: boolean
  onChange?: (status: string) => void
  filter?: (option: Option) => boolean
  placeholder?: string
  projectId?: string
  onFocus?: () => void
  onBlur?: () => void
}

const StatusComponent: React.FC<StatusProps> = ({
  status,
  disabled,
  placeholder,
  onChange,
  filter,
  projectId: propsProjectId,
  onFocus,
  onBlur,
}) => {
  const { t } = useTranslation('projects', { keyPrefix: 'ticket.status' })
  const { projectId: paramsProjectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const projectId = paramsProjectId ?? propsProjectId
  const { data: project } = useGetProject(projectId, teamspaceId)

  const statusCategoryOptions = useStatusCategoryOptions()

  const options = useMemo(() => {
    const options = project.settings.statuses.map((s) => {
      const color = statusCategoryOptions.find((option) => option.value === s.category)?.color
      return {
        value: s.id,
        label: s.name,
        color,
      }
    })

    if (filter) {
      return options.filter(filter)
    }

    return options
  }, [project])

  useEffect(() => {
    if (options && !status) {
      onChange?.(options?.[0]?.value)
    }
  }, [project, status])

  return (
    <Select
      buttonClassName="min-w-[51px]"
      value={status}
      title={t('statusSelectLabel')}
      options={options}
      disabled={disabled}
      onChange={onChange}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

const Status = withLoader(StatusComponent, <Skeleton isActive className="w-[51px]" />)

export default Status
