import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAtom, useSetAtom } from 'jotai'
import { ScopeProvider } from 'jotai-scope'
import { blue, emerald, yellow } from 'tailwindcss/colors'
import { useTranslation } from 'react-i18next'

import Filter from '@/projects/components/Filter'
import BoardBase from '@/projects/components/Board'

import { withLoader } from '@/utils/hocs/withLoader'
import { useGetProject, useGetTicketList, usePatchTicket } from '@/projects/queries'
import { useGetSprints } from '@/projects/queries/sprints'

import {
  filter as filterAtom,
  columns as columnsAtom,
  handleTicketDrop as handleTicketDropAtom,
} from '@/projects/store'

import { SprintStatus } from '@/projects/models/ISprint'
import { ColumnType, IColumn } from '@/projects/models/IProject'
import { UserType } from '@/auth/models/IUser'

export interface BacklogComponentProps {}

const BacklogComponent: React.FC<BacklogComponentProps> = () => {
  const { projectId, teamspaceId } = useParams<{ projectId: string; teamspaceId: string }>()
  const [filter] = useAtom(filterAtom)
  const { t } = useTranslation('projects', { keyPrefix: 'sprint' })

  const { mutateAsync: patchTicket } = usePatchTicket(teamspaceId, projectId)
  const { data: project } = useGetProject(projectId, teamspaceId)
  const setHandleTicketDrop = useSetAtom(handleTicketDropAtom)

  useEffect(() => {
    const handlePatch = async (uuid: string, columnId: string) => {
      const isMovedToBacklog = columnId === 'backlog'
      if (project.sprints_enabled) {
        await patchTicket({ id: uuid, sprint_id: isMovedToBacklog ? null : columnId, in_backlog: isMovedToBacklog })
      } else {
        await patchTicket({ id: uuid, in_backlog: isMovedToBacklog })
      }
    }
    setHandleTicketDrop(() => handlePatch)
  }, [])

  const {
    data: tickets,
    isFetching: isTicketsFetching,
    isLoading,
    isPending,
  } = useGetTicketList(projectId, teamspaceId, filter)
  const { data: sprints } = useGetSprints(projectId, teamspaceId)

  const [, setColumns] = useAtom(columnsAtom)

  useEffect(() => {
    const columnsInit = []
    columnsInit.push({
      id: 'backlog',
      title: t('backlogLabel'),
      color: yellow[500],
      type: ColumnType.backlog,
      cards:
        tickets
          ?.map((ticket) => ({ ...ticket, id: ticket.id, number: ticket.ticket_id }))
          ?.filter((ticket) => (project.sprints_enabled ? !ticket.sprint_id : ticket.in_backlog)) || [],
    })
    if (project.sprints_enabled) {
      columnsInit.push(
        ...sprints
          .filter((sprint) => sprint.status !== SprintStatus.COMPLETED)
          .sort((a, b) => (a.status === SprintStatus.ACTIVE ? -1 : b.status === SprintStatus.ACTIVE ? 1 : 0))
          .map<IColumn>((sprint) => {
            const isActiveSprint = sprint.status === SprintStatus.ACTIVE
            return {
              id: sprint.id,
              title: sprint.name,
              color: isActiveSprint ? emerald[500] : blue[600],
              type: ColumnType.sprint,
              isActiveSprint,
              cards:
                tickets
                  ?.map((ticket) => ({ ...ticket, id: ticket.id, number: ticket.ticket_id }))
                  ?.filter((ticket) => ticket.sprint_id === sprint.id) || [],
            }
          }),
      )
    } else {
      columnsInit.push({
        id: 'board',
        title: t('boardLabel'),
        color: emerald[500],
        type: ColumnType.board,
        cards:
          tickets
            ?.map((ticket) => ({ ...ticket, id: ticket.id, number: ticket.ticket_id }))
            ?.filter((ticket) => !ticket.in_backlog) || [],
      })
    }

    if (project.sprints_enabled && project.user_type === UserType.regular) {
      columnsInit.push({
        id: 'new-sprint',
        title: t('createLabel'),
        color: '#1B64F2',
        type: ColumnType.newSprint,
        cards: [],
      })
    }

    setColumns(columnsInit)
  }, [tickets, sprints])

  return (
    <>
      <Filter />
      <BoardBase isLoading={isLoading || isTicketsFetching || isPending} />
    </>
  )
}

export const Backlog = withLoader(
  () => (
    <ScopeProvider atoms={[columnsAtom, filterAtom, handleTicketDropAtom]}>
      <BacklogComponent />
    </ScopeProvider>
  ),
  <div className="h-full w-full animate-pulse rounded bg-wall-secondary-bg-light dark:bg-wall-secondary-bg-dark" />,
)

export default Backlog
