import { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { openTelegramLink } from '@telegram-apps/sdk-react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { FallbackProps } from 'react-error-boundary'

import { Button } from 'flowbite-react'

export const ErrorBoundary: React.FC<FallbackProps> = ({ error }) => {
  const { t } = useTranslation('common', { keyPrefix: 'errorBoundary' })
  const navigate = useNavigate()
  const [errorUUID, setErrorUUID] = useState('')

  const getIsNotFoundError = () => {
    return error instanceof AxiosError && [401, 404].includes(error.response?.status || 0)
  }

  useEffect(() => {
    if (!getIsNotFoundError()) {
      const uuid = Sentry.captureException(error)
      setErrorUUID(uuid)
    }
  }, [error])

  if (getIsNotFoundError()) {
    return <Navigate to="/" />
  }

  return (
    <div className="flex h-dinamic flex-col justify-center px-3">
      <div className="flex flex-col items-center justify-center gap-3 rounded border border-wall-gray-light p-3 text-wall-gray-neutral dark:border-wall-gray-dark">
        <img src="/logo.svg" className="h-24 w-24" />
        <p>
          {t('title')}{' '}
          <span
            className="cursor-pointer text-wall-main-light underline dark:text-wall-main-dark"
            onClick={() => openTelegramLink('https://t.me/the_wall_supportbot')}
          >
            {t('supportLabel')}
          </span>
        </p>
        <p className="whitespace-normal">
          {t('sendIdLabel')}{' '}
          <span className="font-medium text-wall-main-light dark:text-wall-main-dark">{errorUUID}</span>
        </p>
        <div className="flex w-full justify-end">
          <Button
            onClick={() => {
              navigate('/')
            }}
            color="gray"
            size="xs"
          >
            {t('getBackLabel')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorBoundary
